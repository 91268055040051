import React, { memo } from 'react';
import styles from './styles.module.scss';
import videoPoster from '../../../assets/images/aboutHubVideoPoster.png';

export const AboutHubVideoContainer = memo(() => (
  <div className={styles.aboutHubVideoContainer}>
    <video
      className={styles.videoElement}
      src="https://storage.googleapis.com/eclipse-cdn/videos/About_hub_en.mp4"
      controls
      poster={videoPoster}
      title="About Hub"
    >
      <track
        kind="captions"
        src=""
        label=""
      />
    </video>
  </div>
));
