import React, { memo } from 'react';
import styles from './styles.module.scss';
import videoPoster from '../../../assets/images/officeVideoPoster.png';

export const OfficeVideoContainer = memo(() => (
  <div className={styles.officeVideoContainer}>
    <video
      className={styles.videoElement}
      src="https://storage.googleapis.com/eclipse-cdn/videos/OfficeVideo.mp4"
      controls
      poster={videoPoster}
      title="Office Video"
    >
      <track
        kind="captions"
        src=""
        label=""
      />
    </video>
  </div>
));
