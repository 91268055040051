/* eslint-disable no-console */
import React, {
  FC, memo, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './styles.module.scss';

interface ILinksOthers {
  links: { link: string, title: string }[];
  showRoadmap: boolean;
  classNameContainer: string;
}

export const LinksOthers:FC<ILinksOthers> = memo(({
  links,
  showRoadmap,
  classNameContainer,
}) => {
  const { t } = useTranslation('footer');
  const lng = localStorage.getItem('lng') || 'en';
  const locale = localStorage.getItem('locale') || 'en';
  const [roadmapLanguage, setRoadmapLanguage] = useState(lng || locale);

  useEffect(() => setRoadmapLanguage(lng), [lng]);

  return (
    <div className={cn(styles.footer_others, classNameContainer)}>
      {links.map(({ link, title }) => (
        <a
          key={title}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={styles.footer_navigation_link}
        >
          {t(title)}
        </a>
      ))}
      {showRoadmap && (
      <a
        href={`https://storage.googleapis.com/eclipse-cdn/files/Roadmap_${roadmapLanguage}.pdf`}
        target="_blank"
        rel="noreferrer"
        className={styles.footer_navigation_link}
      >
        Roadmap
      </a>
      )}
    </div>
  );
});
